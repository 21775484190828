define("discourse/plugins/poll/initializers/extend-for-poll", ["exports", "@ember/object", "discourse/widgets/glue", "discourse-common/lib/get-owner", "discourse-common/utils/decorators", "discourse/lib/plugin-api"], function (_exports, _object, _glue, _getOwner, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function initializePolls(api) {
    var _dec, _obj;
    const register = (0, _getOwner.getRegister)(api);
    api.modifyClass("controller:topic", {
      subscribe() {
        this._super(...arguments);
        this.messageBus.subscribe("/polls/" + this.get("model.id"), msg => {
          const post = this.get("model.postStream").findLoadedPost(msg.post_id);
          if (post) {
            post.set("polls", msg.polls);
          }
        });
      },
      unsubscribe() {
        this.messageBus.unsubscribe("/polls/*");
        this._super(...arguments);
      }
    });
    let _glued = [];
    let _interval = null;
    function rerender() {
      _glued.forEach(g => g.queueRerender());
    }
    api.modifyClass("model:post", (_dec = (0, _decorators.observes)("polls"), (_obj = {
      _polls: null,
      pollsObject: null,
      pollsChanged() {
        const polls = this.polls;
        if (polls) {
          this._polls = this._polls || {};
          polls.forEach(p => {
            const existing = this._polls[p.name];
            if (existing) {
              this._polls[p.name].setProperties(p);
            } else {
              this._polls[p.name] = _object.default.create(p);
            }
          });
          this.set("pollsObject", this._polls);
          rerender();
        }
      }
    }, (_applyDecoratedDescriptor(_obj, "pollsChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "pollsChanged"), _obj)), _obj)));
    function attachPolls($elem, helper) {
      const $polls = $(".poll", $elem);
      if (!$polls.length || !helper) {
        return;
      }
      const post = helper.getModel();
      api.preventCloak(post.id);
      post.pollsChanged();
      const polls = post.pollsObject || {};
      const votes = post.polls_votes || {};
      _interval = _interval || setInterval(rerender, 30000);
      $polls.each((idx, pollElem) => {
        const $poll = $(pollElem);
        const pollName = $poll.data("poll-name");
        let poll = polls[pollName];
        let pollPost = post;
        let vote = votes[pollName] || [];
        const quotedId = $poll.parent(".expanded-quote").data("post-id");
        if (quotedId && post.quoted[quotedId]) {
          pollPost = post.quoted[quotedId];
          pollPost = _object.default.create(pollPost);
          poll = _object.default.create(pollPost.polls.find(p => p.name === pollName));
          vote = pollPost.polls_votes || {};
          vote = vote[pollName] || [];
        }
        if (poll) {
          const titleElement = pollElem.querySelector(".poll-title");
          const attrs = {
            id: `${pollName}-${pollPost.id}`,
            post: pollPost,
            poll,
            vote,
            titleHTML: titleElement && titleElement.outerHTML,
            groupableUserFields: (api.container.lookup("site-settings:main").poll_groupable_user_fields || "").split("|").filter(Boolean)
          };
          const glue = new _glue.default("discourse-poll", register, attrs);
          glue.appendTo(pollElem);
          _glued.push(glue);
        }
      });
    }
    function cleanUpPolls() {
      if (_interval) {
        clearInterval(_interval);
        _interval = null;
      }
      _glued.forEach(g => g.cleanUp());
      _glued = [];
    }
    api.includePostAttributes("polls", "polls_votes");
    api.decorateCooked(attachPolls, {
      onlyStream: true,
      id: "discourse-poll"
    });
    api.cleanupStream(cleanUpPolls);
  }
  var _default = _exports.default = {
    name: "extend-for-poll",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializePolls);
    }
  };
});